import gql from 'graphql-tag';
import { Country_Subdivision } from 'lib/introspection';
import {
  AddressFragment,
  PersonFragment,
  OrgFragment,
  MedicalEvent,
  NoteSubMedical,
  PetFragment,
} from './fragments';

export interface AddressInput {
  city?: null | string;
  line_1?: null | string;
  line_2?: null | string;
  locality?: null | string;
  postal_code?: null | string;
  // TODO: this should be a state code enum
  state_code?: string;
  subdivision_code?: keyof typeof Country_Subdivision | null;
}

export const createUpdateUser = gql`
  mutation($input: String) {
    create_update_user_for_auth(invite_token: $input) {
      id
      profile {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

//Input_Organization_Person_Update
export const updatePerson = gql`
  mutation($input: Input_Organization_Person_Update) {
    update_organization_person(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragment}
`;

export const deletePet = gql`
  mutation delete_organization_pet($input: Input_Organization_Pet_Delete!) {
    delete_organization_pet(input: $input) {
      pet_id
    }
  }
`;

export const addCustomerToOrg = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      stripe_customer_id
      stripe_subscription_id
    }
  }
`;

// TODO: Remove this when we update to the new organization setting page as default
export const updateOrganization = gql`
  mutation($input: Input_Organization_Update!) {
    update_organization(input: $input) {
      ...OrgFragment
      locations {
        ...AddressFragment
      }
    }
  }
  ${OrgFragment}
  ${AddressFragment}
`;

export const updateOrganizationLocaleSettings = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      address_style
      phone_country_code
      default_currency
      measurement_system
    }
  }
`;

export const createUpdateUserAndProfile = gql`
  mutation createUpdateUserAndProfile($input: UserProfileInput!) {
    create_update_user_for_auth {
      id
    }
    update_user_profile(input: $input) {
      id
      first_name
      last_name
      phone
      email
    }
  }
`;

export const createExternalOrganization = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      external_organizations {
        id
        name
      }
    }
  }
`;

export const createOrganization = gql`
  mutation($input: Input_Organization_Create!) {
    create_organization(input: $input) {
      id
      name
      phone
      email
      type
      additional_services
      primary_address {
        ...AddressFragment
      }
    }
  }
  ${AddressFragment}
`;

export const inviteUser = gql`
  mutation($input: Input_InviteUser!) {
    invite_user(input: $input) {
      invite_token
    }
  }
`;

export const createUpdateUserFor = gql`
  mutation($input: String) {
    create_update_user_for_auth(invite_token: $input) {
      id
      profile {
        first_name
      }
    }
  }
`;

export const deleteMedical = gql`
  mutation($input: Input_Pet_MedicalEvent_Delete!) {
    pet_medical_event_delete(input: $input) {
      medical_event_id
      organization_id
    }
  }
`;

export const addNewMedical = gql`
  mutation($input: Input_Pet_MedicalEvent_Create!) {
    pet_medical_event_create(input: $input) {
      ...MedicalEvent
    }
  }
  ${MedicalEvent}
`;

export const updateMedical = gql`
  mutation($input: Input_Pet_MedicalEvent_Update!) {
    pet_medical_event_update(input: $input) {
      ...MedicalEvent
    }
  }
  ${MedicalEvent}
`;

export const deleteMedicalRecord = gql`
  mutation($input: Input_Pet_MedicalEvent_Record_Delete!) {
    pet_medical_event_record_delete(input: $input) {
      organization_id
    }
  }
`;

export const medicalRecordSurgery = gql`
  mutation($input: Input_Pet_MedicalEvent_Record!) {
    pet_medical_event_record_surgery_create(input: $input) {
      id
    }
  }
`;

export const medicalRecordSurgerySpay = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_Surgery_LineItem_SpayNeuter_Create!
  ) {
    pet_medical_event_record_surgery_line_item_spay_neuter_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordDental = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_Surgery_LineItem_Dental_Create!
  ) {
    pet_medical_event_record_surgery_line_item_dental_create(input: $input) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordDiagnosis = gql`
  mutation($input: Input_Pet_MedicalEvent_Record!) {
    pet_medical_event_record_diagnosis_create(input: $input) {
      id
    }
  }
`;

export const medicalRecordDiagnosisGeneral = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_Diagnosis_LineItem_DiagnosisGeneral_Create!
  ) {
    pet_medical_event_record_diagnosis_line_item_diagnosis_general_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
      ... on Pet_MedicalEvent_Record_Diagnosis_LineItem_DiagnosisGeneral {
        name
      }
    }
  }
`;

export const medicalRecordOther = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_Surgery_LineItem_SurgeryOther_Create!
  ) {
    pet_medical_event_record_surgery_line_item_surgery_other_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordMedical = gql`
  mutation($input: Input_Pet_MedicalEvent_Record!) {
    pet_medical_event_record_medication_treatment_create(input: $input) {
      id
    }
  }
`;

export const medicalRecordMedicalTreatment = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_MedicationTreatment_LineItem_Medication_Create!
  ) {
    pet_medical_event_record_medication_treatment_line_item_medication_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
      ... on Pet_MedicalEvent_Record_MedicationTreatment_LineItem_Medication {
        dose
        end_date
        frequency
        name
        start_date
      }
    }
  }
`;

export const medicalRecordLab = gql`
  mutation($input: Input_Pet_MedicalEvent_Record!) {
    pet_medical_event_record_lab_misc_create(input: $input) {
      id
    }
  }
`;

export const medicalRecordLabBlood = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_BloodWork_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_blood_work_create(
      input: $input
    ) {
      id
    }
  }
`;

export const medicalRecordLabCytology = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Cytology_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_cytology_create(input: $input) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLabOther = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_LabOther_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_lab_other_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLabRadio = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Radiography_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_radiography_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLabSerology = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Serology_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_serology_create(input: $input) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLab4DX = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Test4dx_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_test_4dx_create(input: $input) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLabUrinalysis = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Urinalysis_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_urinalysis_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordLabBio = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_LabMisc_LineItem_Biopsy_Create!
  ) {
    pet_medical_event_record_lab_misc_line_item_biopsy_create(input: $input) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordVaccine = gql`
  mutation($input: Input_Pet_MedicalEvent_Record!) {
    pet_medical_event_record_wellness_vaccines_create(input: $input) {
      id
    }
  }
`;

export const medicalRecordVaccineExam = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Exam_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_exam_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
    }
  }
`;

export const medicalRecordVaccineFecal = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FecalResult_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_fecal_result_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
      ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FecalResult {
        positive
      }
    }
  }
`;

export const medicalRecordVaccineFel = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FivFelvTest_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_fiv_felv_test_create(
      input: $input
    ) {
      id
      note {
        id
        text
      }
      ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FivFelvTest {
        fiv_positive
        felv_positive
      }
    }
  }
`;

export const medicalRecordVaccineFlea = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FleaTick_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_flea_tick_create(
      input: $input
    ) {
      id
      ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FleaTick {
        flea_positive
        tick_positive
      }
    }
  }
`;

export const medicalRecordVaccineHeartworm = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Heartworm_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_heartworm_create(
      input: $input
    ) {
      id
      ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Heartworm {
        positive
      }
    }
  }
`;

export const medicalRecordVaccineVaccine = gql`
  mutation(
    $input: Input_Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Vaccine_Create!
  ) {
    pet_medical_event_record_wellness_vaccines_line_item_vaccine_create(
      input: $input
    ) {
      id
      ...note
      ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Vaccine {
        name
        manufacturer
        serial_number
        expiration_date
      }
    }
  }
  ${NoteSubMedical}
`;

export const petIntake = gql`
  mutation intakePet($input: Input_Pet_Event_Intake!) {
    pet_event_intake(input: $input) {
      ...PetFragment
    }
  }
  ${PetFragment}
`;

export const getClinicAndVet = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      clinics {
        id
        name
        vets {
          name
        }
      }
    }
  }
`;

export const addPerson = gql`
  mutation($input: Input_Organization_Person_Create!) {
    create_organization_person(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragment}
`;

export interface AddPersonResult {
  create_organization_person: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address: {
      postal_code: string;
      country_code: string;
      subdivision_code: string;
      locality: string;
      line_1: string;
      line_2: string;
    };
    // this is incomplete and probably all is null-ish
  };
}

export const addPeople = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      external_persons {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

export interface AddPeopleResult {
  update_organization_settings: {
    external_persons: Array<{
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
      address: {
        postal_code: string;
        country_code: string;
        subdivision_code: string;
        locality: string;
        line_1: string;
        line_2: string;
      };
      // this is incomplete and probably all is null-ish
    }>;
  };
}

export const updatePeople = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      external_persons {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

export const deletePerson = gql`
  mutation deletePerson($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      external_persons {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

export const OutcomeDied = gql`
  mutation($input: Input_Pet_Event_DiedInCare!) {
    pet_event_died_in_care(input: $input) {
      id
      date
      event_type
    }
  }
`;

export const OutcomeEuthanized = gql`
  mutation($input: Input_Pet_Event_Euthanized!) {
    pet_event_euthanized(input: $input) {
      id
      date
      event_type
      euthanized_type
    }
  }
`;

export const OutcomeReturn = gql`
  mutation($input: Input_Pet_Event_Return!) {
    pet_event_return(input: $input) {
      id
      date
    }
  }
`;

export const OutcomeTransfer = gql`
  mutation($input: Input_Pet_Event_Transfer!) {
    pet_event_transfer(input: $input) {
      date
      event_type
      id
      organization {
        id
        name
      }
      pet {
        id
        name
      }
    }
  }
`;

export const OutcomeAdoption = gql`
  mutation($input: Input_Pet_Event_Adoption!) {
    pet_event_adoption(input: $input) {
      id
      date
      event_type
      to {
        id
        first_name
      }
    }
  }
`;

export const OutcomeIntake = gql`
  mutation($input: Input_Pet_Event_Intake!) {
    pet_event_intake(input: $input) {
      internal_id
    }
  }
`;

export const getOrganization = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      external_organizations {
        id
        name
      }
    }
  }
`;

export const deletePetImage = gql`
  mutation deletePetImage($input: Input_Pet_DeleteImage!) {
    delete_pet_image(input: $input) {
      id
    }
  }
`;

export const petUpdate = gql`
  mutation updateOrganizationPet($input: Input_Organization_Pet_Update!) {
    update_organization_pet(input: $input) {
      ...PetFragment
    }
  }
  ${PetFragment}
`;

export const personUpdate = gql`
  mutation updateOrganizationPerson($input: Input_Organization_Person_Update!) {
    update_organization_person(input: $input) {
      ...PersonFragment
    }
  }
  ${PersonFragment}
`;

export const deleteNote = gql`
  mutation deleteNote($input: Input_Pet_DeleteImage!) {
    delete_note(input: $input) {
      note_id
    }
  }
`;

export const updateNote = gql`
  mutation updateNote($input: Input_Note_Update!) {
    update_note(input: $input) {
      id
      text
    }
  }
`;

export const createFileUpload = gql`
  mutation createFileUpload {
    create_file_upload {
      file_id
      upload_url
    }
  }
`;

export const updatePetFinder = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      pet_finder_user
      pet_finder_password
    }
  }
`;

export const updateAdoptPet = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      adopt_a_pet_password
      adopt_a_pet_user
    }
  }
`;

export const updateUserRole = gql`
  mutation set_organization_user_roles(
    $input: Input_OrganizationUserRoles_Set!
  ) {
    set_organization_user_roles(input: $input) {
      role
    }
  }
`;

export const deleteCustomReport = gql`
  mutation delete_custom_report($input: Input_CustomReport_Delete!) {
    delete_custom_report(input: $input) {
      id
    }
  }
`;

export const DeletePetEvent = gql`
  mutation deletePetEvent($input: Input_Pet_Event_Delete!) {
    pet_event_delete(input: $input) {
      id
    }
  }
`;

export const medicalAddReminder = gql`
  mutation create_medical_event_reminder(
    $input: Input_Pet_MedicalEvent_Reminder_Create!
  ) {
    create_medical_event_reminder(input: $input) {
      id
      title
      schedules {
        date
        end_date
        recurrence_pattern
      }
    }
  }
`;

export const medicalRemoveReminder = gql`
  mutation delete_medical_event_reminder(
    $input: Input_Pet_MedicalEvent_Reminder_Delete!
  ) {
    delete_medical_event_reminder(input: $input) {
      id
    }
  }
`;

export const medicalDeleteReminder = gql`
  mutation update_medical_event_reminder(
    $input: Input_Pet_MedicalEvent_Reminder_Update!
  ) {
    update_medical_event_reminder(input: $input) {
      id
      title
      message
    }
  }
`;

export const medicalUpdateReminder = gql`
  mutation update_medical_event_reminder(
    $input: Input_Pet_MedicalEvent_Reminder_Update!
  ) {
    update_medical_event_reminder(input: $input) {
      id
      title
      message
      schedules {
        id
        date
        end_date
        recurrence_pattern
      }
    }
  }
`;

export const UpdateNotification = gql`
  mutation update_notification($input: Input_Notification_Update!) {
    update_notification(input: $input) {
      is_read
      id
    }
  }
`;

export const partnerUpdate = gql`
  mutation updateOrganizationPartner($input: PartnerUpdateInput!) {
    update_partner(input: $input) {
      id
      organization_id
    }
  }
`;
