// @flow
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

export const gqlMutationHandler = (propName, refetchQueries = []) => ({
  props: ({ mutate }) => ({
    [propName]: (input) =>
      mutate({
        variables: { input },
        refetchQueries,
      }),
  }),
});

export const cleanNullKeys = (obj) =>
  Object.keys(obj)
    .filter((key) => !!obj[key] || obj[key] === false)
    .reduce((res, key) => {
      res[key] = obj[key];
      return res;
    }, {});

export const catchToast = (errorMessage = '', promise) => {
  promise.catch((err) => {
    toast.error(errorMessage);
  });
};

export const sortDates = (arr) =>
  arr.sort((objA, objB) => new Date(objB.date) - new Date(objA.date));

export const formatDate = (date) => {
  return date ? format(parseISO(date), 'd MMMM, yyyy') : null;
};

export const formatDateTime = (date) =>
  date ? format(parseISO(date), 'd MMMM, yyyy, h:mm a') : null;
